import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { lazyWithRetry } from "./utils/lazyWithRetry";
import { CircularProgress, Box } from "@mui/material";

const theme = createTheme();

const MainRoutes = lazyWithRetry(() => import("./MainRoutes"));
const ReviewPage = lazy(() => import("./routes/ReviewPage/ReviewPage"));
const ChatWidgetsContent = lazy(() =>
  import("./routes/chatWidgets/ChatWidgetsContent")
);
const ReviewWidgetsContent = lazy(() =>
  import("./routes/reviewWidgets/widget/ReviewWidgetsContainer")
);
const VerifyUserEmail = lazyWithRetry(() =>
  import("./routes/verifyEmail/VerifyUserEmail")
);
const Notifications = lazyWithRetry(() =>
  import("./routes/notifications/notifications")
);
const AdminRouter = lazyWithRetry(() => import("./routes/admin/adminRouter"));

const VerifyAccess = lazyWithRetry(() =>
  import("./routes/subUsers/VerifyAccess")
);

const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Switch>
            <Route path="/review-page/:reqId" component={ReviewPage} />
            <Route
              path="/widgets/chat/:widgetId"
              component={ChatWidgetsContent}
            />
            <Route
              path="/widgets/review/:widgetId"
              component={ReviewWidgetsContent}
            />
            <Route path="/verifyEmail/:id" component={VerifyUserEmail} />
            <Route path="/verifyAccess/:id" component={VerifyAccess} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/admin" component={AdminRouter} />
            <Route>
              <GoogleOAuthProvider clientId="973565498656-tk8odvgovkb2n8odu7v2lm4okqkf6ofn.apps.googleusercontent.com">
                <MainRoutes />
              </GoogleOAuthProvider>
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
