import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { env, isProd } from "./constants/envInfo";
import App from "./App";

if (isProd) {
  Sentry.init({
    dsn: "https://405428b14d5d4f06b64b6baf8dc6bda1@o1356682.ingest.sentry.io/6660714",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: env,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
